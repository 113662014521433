.feature-gateway-messaging {
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.center {
      justify-content: center;
    }

    .MuiSvgIcon-root {
      margin-right: 4%;
      width: 1.4em;
      height: 1.4em;
    }
  }

  .MuiButtonBase-root.MuiButton-root {
    //styleName: Button/Button - Focused;
    letter-spacing: 1.7599999904632568px;
    text-align: center;
    color: #fff;
    font-size: 1.8em;
    background: #F9F9F91A;
    padding: 10px 30px;
    width: 95%;
    font-weight: 600;
  }
}
