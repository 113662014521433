.ad-badge {
  background-color: rgb(0 0 0 / 82%);
  position: absolute;
  right: 5%;
  z-index: 3;
  top: 5%;
  padding: 5px 10px;
  padding: 0px;
  border-radius: 10px;

  .badge {
    color: #000;
    font-weight: bold;
    background-color: #F2DE3C;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 20px;
  }

  .time {
    color: #fff;
    padding-left: 5px;
    font-size: 20px;
    padding: 10px 10px;
  }
} 

@media screen and (max-width: 991px) {
  .ad-badge {
    
  }
}
