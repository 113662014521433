.avatar-picker {
  .MuiDialogContentText-root {
    margin-block-end: 15px;
  }
  .avatar-container {
    margin-block-start: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px 20px;
    .MuiAvatar-root {
      border: 2px solid #000;
      cursor: pointer;
      &:hover {
        border-color: #8dd14f;
      }
      &.current {
        border-color: #1976d2;
      }
    }
  }
  .MuiSvgIcon-root {
    color: #1976d2;
  }
  .errors {
    color: #f73b63;
  }
}

@media screen and (max-width: 991px) {
  .avatar-picker {
    padding-top: 70px;
  }
}
