.interactive-content {
  position: absolute;
  width: 100%;
  z-index: 6;
  height: 100%;
  top: 0;
  z-index: 0;

  .toast {
    position: absolute;
    bottom: 5%;
    right: -10%;
    display: flex;
    left: auto;
    background-color: #ffffff;
    padding: 1% 2%;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    border: 6px solid #F2DE3C;
    border-radius: 20px;

    .advertiser-prompt {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .countdown-timer {
        position: relative;
        top: auto;
        color: #000;
      }

      .ad-prompt-container {
        display: flex;
        align-items: flex-start;
        max-height: 80px;
        flex-direction: column;

        .title {
          font-size: 1.8rem;
        }
        .sub-title {
          font-size: 1.4rem;
          color: gray;
        }
      }
    }
  
    &.viewable {
      right: 5%; /* Slide in from left */
      opacity: 1;
    }
  
    &.hidden {
      right: -5%; /* Slide out to right */
      opacity: 0;
    }
  }

  .countdown-timer {
    color: white;
    position: absolute;
    right: 5%;
    top: 120px;
    font-size: 1.8rem;
    display: flex;
    align-content: center;
    align-items: center;
  
    label {
      font-size: 1.5rem;
    }

    > div {
      margin-left: 20px;
    }
  }

  &.video-player {
    .video-js {
      width: 48% !important;
      padding-top: 27.0% !important;
      margin-left: 5%;
      top: 25%;
      height: auto;

      .vjs-control-bar {
        display: none;
      }
    }
  }  

  .ad-format {
    &.squeezeback {

    }
  }

  .ad-format-type {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; 
    background-size: cover;

    .component-container {

    }
  }
}

.gradient-background {
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 0;
  bottom: 0px;
  right: 0;
  color: #fff;
  border-radius: 0;
  padding: 0;
  transition: opacity 0.5s;
  display: flex !important;
  align-items: center;
}

.TOAST {
  .interactive-content {
    z-index: 9;
    height: 25%;
    bottom: 5%;
    top: auto;
  }
}

.PROMPT {
  &.ad-video {
    .interactive-content {

    }
    .lower-third .gradient-background {
      height: 25%;
    }
  }

  .ad-badge {
    top: 15%;
  }

  .interactive-content {
    height: 100%;
    bottom: 0;
    z-index: 6;
    top: auto;
  }
  .lower-third {
    .advertiser-message {
      bottom: 0;
      position: absolute;
      color: white;
      z-index: 2;
      left: 5%;
      display: flex;
      padding-bottom: 40px;
  
      .ad-message-container {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 50%;
  
        .title {
          font-size: 2.2em;
          color: #F2DE3C;
        }
  
        .description {
          font-size: 1.8em;
        }
      }
      img {
        flex: 1;
        max-height: 270px;
      }
    }
    .advertiser-prompt {
      bottom: 0;
      position: absolute;
      height: 100%;
      color: white;
      z-index: 2;
      right: 5%;
      display: flex;
      overflow: hidden;
      max-height: 258px;
      padding-bottom: 10px;
      max-width: 38%;
  
      img {
        flex: 1;
        height: 550px;
        padding-right: 45px;
      }
  
      .ad-prompt-container {
        display: flex;
        justify-content: space-between;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        .ad-offer-message {
          font-size: 2.5rem;
          line-height: 1.2em;
          text-shadow: 4px 4px 54px rgba(24, 10, 56, 0.60);
        }

        .title {
          font-size: 2.2em;
          color: #F2DE3C;
          width: 100%;
        }

        .ad-offer-container {
          display: flex;
          align-items: center;
          max-height: 80px;

          .ad-offer-copy {
            display: flex;
            flex-direction: column;
            line-height: 2.0em;

            .sub-title {
              font-size: 1.8em;
              color: #fff;
            }
    
            .message {
              font-size: 1.8em;
              color: #fff;
            }
          }

          .logo {
            display: flex;
          }
        }
  
        .description {
          margin-top: 15px;
          font-size: 1.5em;
          font-style: italic;
          font-weight: 500;
          width: 100%;
          line-height: 1.2em;
          text-shadow: 4px 4px 54px rgba(24, 10, 56, 0.60);
        }
  
        .ad-cta-prompt {
          color: #DCDCDC;
          text-shadow: 4px 4px 15px rgba(24, 10, 56, 0.80);
          font-size: 1.7em;
          font-style: italic;
          font-weight: 500;
          line-height: normal;
        }
  
        img {
          height: 209px;
          padding-right: 0;
        }
      }
    }

    .gradient-background {
      opacity: 1;
      background: linear-gradient(360deg, rgba(23, 9, 55, 0.90) 60%, rgba(23, 9, 55, 0.00) 100%);
      height: 30%;
    }
  }
}

.triviaAd {
  .countdown {
    justify-content: flex-end;
    display: flex;
    font-size: 1.4em;
  }

  .trivia-container {
    justify-content: flex-end;
    margin-right: 5%;
    width: 77%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0;
    bottom: 0px;
    right: 0;
    color: #fff;
    border-radius: 0;
    padding: 0;
    transition: opacity 0.5s;
    // background: #000;
    // background: linear-gradient(180deg,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.49) 20%,rgba(0, 0, 0, 0.93) 55%,#000000 92.71%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .text-container {
      margin-bottom: 20px;
      max-height: 10.6rem; /* Adjust based on line height and font size */
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* Number of lines to show */
      -webkit-box-orient: vertical;

      h2 {
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 2.0rem;
        font-weight: 400;
        line-height: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;

        &.question {
          color: #F2DE3C;
        }

        &.answer {
          color: #8dd14f;
        }
      }
    }

    .progress-bar {
      margin-bottom: 40px;
    }

    p {
      margin-block-start: 0;
    }

    .close {
      position: absolute;
      right: 2%;
      top: 2%;
      cursor: pointer;
      font-size: 21px;
      color: #16a4fa;
    }

    .component-container {
      -webkit-box-flex: 0;
      -webkit-flex: none;
      -ms-flex: none;
      flex: none;
      min-width: 50%;
      max-width: 50%;
    }

    ol, ul {
      padding-inline-start: 0;
      margin-block-start: 0.5em;
      // margin-block-end: 0;
      display: flex;
      flex-direction: column;
      list-style-type: upper-alpha;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center; 
      position: relative;
      padding: 2% 0;
      // list-style: none;
      border-radius: 50px;
      border: 5px solid #fff;
      overflow: hidden;
      margin-bottom: 20px;

      -webkit-transition: all 0.2s;
      -webkit-transition-timing-function: ease-in-out;
      transition: all 0.2s;
      transition-timing-function: ease-in-out;

      &:hover {
        cursor: pointer;
      }

      svg {
        z-index: 1;
        margin-right: 5%;
        font-size: 2.3em;
      }

      label {
        z-index: 1;
        line-height: 40px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
      }

      h2 {
        font-weight: 900;
        text-transform: capitalize;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .title {
        font-size: 1.6em;
        padding-left: 5%;
        flex: 1;
      }

      .value {
        right: 5.5%;
        font-size: 1.6em;
      }

      .bar {
        -webkit-transition: all 0.2s;
        -webkit-transition-timing-function: ease-in-out;
        transition: all 0.2s;
        transition-timing-function: ease-in-out;
        border-radius: 50px;
        z-index: 0;
        top: 0;
        background: #00cbff;
        cursor: pointer;
        height: 100%;
        position: absolute;
        z-index: 0;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    li:nth-child(1) .bar {
      background: #41b2ec;
    }
    li:nth-child(2) .bar {
      background: #f3ad3b;
    }
    li:nth-child(3) .bar {
      background: #8dd14f;
    }
    li:nth-child(4) .bar {
      background: #f73b63;
    }
  }

  .results {
    width: 100%;
    float: left;
    margin-bottom: 10px;
    font-size: 1.5em;
    .countdown {
      float: left;
    }
  
    .response {
      float: right;
      &.correct {
        color: #8dd14f;
      }
    }
  }

  &.viewable {
    > div { opacity: 1; }
  }

  &.active {
    li {
      &:hover {
        border: 5px solid #fff;
      }

      &:focus {
        color: #000;
        border-radius: 44.803px;
        border: 5.376px solid #F2DE3C;
        background: #FFF;
        box-shadow: 0px 3.5842244625091553px 7.1684489250183105px 3.5842244625091553px rgba(255, 196, 0, 0.35);
      }
    }
  }

  &.inactive {
    li {
      border: 5px solid gray;
      color: gray;
      background: rgb(255 255 255 / 15%);
      opacity: 0.3;

      &.correct {
        border-color: #8dd14f;
        color: #000;
        background: #fff;
        opacity: 1;
        svg {
          color: #8dd14f;
        }
        .bar {
          background: #8dd14f !important;
        }
      }

      &.incorrect {
        border-color: #f73b63;
        color: #000;
        background: #fff;
        svg {
          color: #f73b63;
        }
        .bar {
          background: #f73b63 !important;
        }
      }

      .bar {
        background: gray !important;
      }
    }
  }

  .advertiser-message {
    bottom: 0;
    position: absolute;
    color: white;
    z-index: 2;
    left: 5%;
    display: flex;

    .ad-message-container {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .ad-message-one {
        font-size: 2.2em;
      }

      .ad-message-two {
        font-size: 1.8em;
      }
    }
    img {
      flex: 1;
      max-height: 270px;
    }
  }

  .advertiser-prompt {
    bottom: 0;
    position: absolute;
    color: white;
    z-index: 2;
    right: 0%;
    display: flex;
    overflow: hidden;
    max-height: 258px;
    width: 40%;

    img {
      flex: 1;
      height: 550px;
      padding-right: 30px;
    }

    .ad-prompt-container {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .ad-offer-message {
        font-size: 2.5rem;
        width: 70%;
        line-height: 1.2em;
        text-shadow: 4px 4px 54px rgba(24, 10, 56, 0.60);
      }

      .ad-cta-prompt {
        color: #DCDCDC;
        text-shadow: 4px 4px 15px rgba(24, 10, 56, 0.80);
        font-size: 1.7em;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.endCardAd {
  .leaderboard-container {
    justify-content: center;
    margin-right: 5%;
    width: 77%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0;
    bottom: 0px;
    right: 0;
    color: #fff;
    border-radius: 0;
    padding: 0;
    transition: opacity 0.5s;
    display: flex !important;
    align-items: center;
    flex: none;
    min-width: 38%;
    max-width: 38%;
    flex-direction: column;

    #lottie-container {
      position: absolute;
      z-index: -1;
      height: 400px;
      position: absolute;
      width: 100%;
      top: 10%;

      display: flex;
      flex-direction: row;
      align-content: space-around;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
    }

    .top-scorers {
      display: flex;
      flex-direction: row;
      align-content: space-around;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;

      li {
        width: 150px;
        height: 150px;
        border-radius: 150px;
        padding: 0;
        margin: 0 15px;
        background-position: center;
        background-size: cover;
        align-items: flex-end;
        overflow: visible;

        &:first-child {
          border: 5.376px solid #70C1A1;

          .rank {
            background-color: #70C1A1;
          }
        }

        &:nth-child(2) {
          width: 225px;
          height: 225px;
          border-radius: 225px;
          border: 5.376px solid #F2DE3C;

          .rank {
            background-color: #F2DE3C;
          }
        }

        &:last-child {
          border: 5.376px solid #16a4fa;

          .rank {
            background-color: #16a4fa;
          }
        }

        &.self {
          border: 5.376px solid #8D46F7;

          .rank {
            background-color: #8D46F7;
          }
        }

        label {
          position: relative;
          top: 60px;
        }

        .rank {
          position: absolute;
          top: auto;
          bottom: -20px;
          height: 40px;
          width: 40px;
          border-radius: 25px;
          text-align: center;
          font-size: 1.5em;
        }
      }
    }

    .remaining-scorers {
      max-height: 570px;
      overflow: hidden;

      li {
        max-height: 40px;
        min-height: 40px;

        &.self {
          border: 5.376px solid #8D46F7;
          background: linear-gradient(149deg, #8D45F8 0%, #70C1A1 100%);
        }
      }
    }

    .close {
      position: absolute;
      right: 2%;
      top: 2%;
      cursor: pointer;
      font-size: 21px;
      color: #16a4fa;
    }

    ol, ul {
      padding-inline-start: 0;
      margin-block-start: 0.5em;
      // margin-block-end: 0;
      display: flex;
      flex-direction: column;
      list-style-type: upper-alpha;
      width: 100%;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center; 
      position: relative;
      padding: 2% 0;
      border-radius: 50px;
      border: 5px solid #fff;
      overflow: hidden;
      margin-bottom: 20px;

      -webkit-transition: all 0.2s;
      -webkit-transition-timing-function: ease-in-out;
      transition: all 0.2s;
      transition-timing-function: ease-in-out;

      &:hover {
        cursor: pointer;
      }

      label {
        z-index: 1;
        line-height: 40px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
      }

      .name {
        font-size: 1.6em;
        padding-left: 5%;
        flex: 1;
      }

      .score {
        padding-right: 5.5%;
        font-size: 1.6em;
      }
    }
  }

  .results {
    width: 100%;
    float: left;
    margin-bottom: 10px;
    font-size: 1.5em;
    .countdown {
      float: left;
    }
  
    .response {
      float: right;
    }
  }

  &.viewable {
    > div { opacity: 1; }
  }

  &.active {
    li {
      &:hover {
        border: 5px solid #fff;
      }

      &:focus {
        color: #000;
        border-radius: 44.803px;
        border: 5.376px solid #F2DE3C;
        background: #FFF;
        box-shadow: 0px 3.5842244625091553px 7.1684489250183105px 3.5842244625091553px rgba(255, 196, 0, 0.35);
      }
    }
  }

  .reward {
    color: #fff;
    text-align: center;
    justify-content: center;
    margin-right: 5%;
    width: 77%;
    height: 100%;
    position: absolute;
    z-index: 1;
    opacity: 0;
    bottom: 0px;
    right: 0;
    color: #fff;
    border-radius: 0;
    padding: 0;
    transition: opacity 0.5s;
    display: flex !important;
    align-items: center;
    flex: none;
    min-width: 38%;
    max-width: 38%;
    flex-direction: column;

    .achievement-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .badge {
        --face: #be9d66;
        --lowlight: #111;
        --side: #896c3b;
        --side-dark: #120e08;
        --coin-size: 15rem;
        --coin-face: url('https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fbadge-lego-alt.png?alt=media&token=1788e535-c164-4b4c-adab-941443b514cd');

        height: var(--coin-size);
        width: var(--coin-size);
        margin: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          display: block;
          position: relative;
          height: var(--coin-size);
          width: var(--coin-size);
          border-radius: 50%;
          background-color: var(--face);
          animation: spin 1.2s linear infinite;
          background-image: var(--coin-face);
          background-size: 100% 100%;
          background-position: center;
          background-blend-mode: overlay;
        }
      }

      p {
        font-size: 1.5em;
      }
    }

    .offer-container {
      h1 {
        text-transform: capitalize;
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 2.8em;
      }
      p {
        font-size: 1.8em;
        margin-block-start: 0;
        span {
          color: #F2DE3C;
        }
      }
      .gateway-tech {
        font-size: 1.8em;
        color: #000;
        background-color: #F2DE3C;
        border-radius: 40px;
        padding: 2% 6%;

        svg {
          font-size: 1.8em;
          padding-right: 10px;
        }
      }
    }
  }

  .advertiser-message {
    bottom: 0;
    position: absolute;
    color: white;
    z-index: 2;
    left: 5%;
    display: flex;

    .ad-message-container {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .ad-message-one {
        font-size: 2.2em;
      }

      .ad-message-two {
        font-size: 1.8em;
      }
    }
    img {
      flex: 1;
      max-height: 270px;
    }
  }

}

@media screen and (max-width: 991px) {
  .App .triviaAd {
    > div {
      height: calc(35vh);
      width: 100%;
      min-height: 35vh;
      h2 {
        font-size: 0.9em;
      }
    }
  }
}

/* animation definitions */
@keyframes spin {
  0% {
    width: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
    animation-timing-function: ease-in;
  }
  
  49.999% {
    width: 0.1rem;
    box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: linear;
  }
  
  50.001% {
    width: 0.1rem;
    box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    transform: translateX(0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: ease-out;
  }
  
  100% {
    width: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
  }
}

@keyframes flip {
  0% {
    height: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
    animation-timing-function: ease-in;
  }
  
  49.999% {
    height: 0.1rem;
    box-shadow:
      0 0.05rem 0 var(--side),
      0 0.1rem 0 var(--side),
      0 0.15rem 0 var(--side),
      0 0.2rem 0 var(--side),
      0 0.25rem 0 var(--side),
      0 0.3rem 0 var(--side),
      0 0.35rem 0 var(--side),
      0 0.4rem 0 var(--side),
      0 0.45rem 0 var(--side),
      0 0.5rem 0 var(--side),
      0 0.55rem 0 var(--side),
      0 0.6rem 0 var(--side),
      0 0.65rem 0 var(--side),
      0 0.7rem 0 var(--side),
      0 0.75rem 0 var(--side);
    transform: translateY(-0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: linear;
  }
  
  50.001% {
    height: 0.1rem;
    box-shadow:
      0 -0.05rem 0 var(--side),
      0 -0.1rem 0 var(--side),
      0 -0.15rem 0 var(--side),
      0 -0.2rem 0 var(--side),
      0 -0.25rem 0 var(--side),
      0 -0.3rem 0 var(--side),
      0 -0.35rem 0 var(--side),
      0 -0.4rem 0 var(--side),
      0 -0.45rem 0 var(--side),
      0 -0.5rem 0 var(--side),
      0 -0.55rem 0 var(--side),
      0 -0.6rem 0 var(--side),
      0 -0.65rem 0 var(--side),
      0 -0.7rem 0 var(--side),
      0 -0.75rem 0 var(--side);
    transform: translateY(0.375rem);
    background-color: var(--lowlight);
    animation-timing-function: ease-out;
  }
  
  100% {
    height: var(--coin-size);
    box-shadow:
      0 0 0 var(--side-dark);
  }
}