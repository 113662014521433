.content-details {
  width: 100%;
  z-index: 1;
  overflow: hidden;
  transform: scale(0.5);
  position: relative;
  top: -10%;
  padding-top: 58.35%;
  overflow: hidden;
  width: 100%;

  .background-image {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    z-index: 0;
    top: 0;
    transform: scale(1.2);

    &.zoom-out {
      transition: transform 800ms ease; /* Set the desired transition duration */
      transform: scale(1.0); /* Start with a slightly zoomed-in state */
    }
  }

  .content-container {
    position: absolute;
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding-left: 5%;
    color: #fff;
    background: linear-gradient(270deg, rgb(23 9 55 / 0%) 0%, #04010b 80%, #04010b00 100%);

    .content-logo {
      width: 65%;
      height: 28%;
      background-position: 0, 0;
      background-size: cover;
    }
    .subHead {
      color: #F2DE3C;
      font-size: 2em;
      margin-block-start: 2%;
    }
    .learnMore {
      color: #DCDCDC;
      font-size: 1.2em;
    }
    .badges {
      color: #DCDCDC;
      font-size: 1.2em;
      margin-block-start: 1.4em;
      margin-block-end: 0.2em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      width: 100%;

      li {
        margin-right: 1%;
        border: 2px solid #DCDCDC;
        padding: 3px 10px;
        border-radius: 10px;
      }
    }
    .genres {
      color: #DCDCDC;
      font-size: 1.2em;
      margin-block-start: 0em;
      margin-block-end: 1.4em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      width: 100%;

      li {
        margin-right: 1%;
      }
    }
    .description {
      margin-block-end: 1.8em;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* Number of lines to show */
      -webkit-box-orient: vertical;
    }
    .button-container {
      justify-content: flex-start;
      display: flex;
      Button {
        border-radius: 50px;
      }
      .watch-interactive {
        background-color: #F2DE3C;
        color: #000;
        padding: 1% 5%;
        align-items: center;
        display: flex;
        height: 56px;
        justify-content: center;
        overflow: hidden;
        padding: 0 1rem;
        position: relative;
        transition: all .2s ease-in-out;
        width: auto;
        margin-right: 0.75rem;
        font-size: 1.3rem;
        padding-right: 1.75rem;

        border: none;
        outline: none;
        // color: #fff;
        // background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        //border-radius: 10px;

        &:before {
          opacity: 1;
          content: '';
          background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
          position: absolute;
          top: -2px;
          left:-2px;
          background-size: 400%;
          z-index: -1;
          animation: glowing 20s linear infinite;
          opacity: 0;
          transition: opacity .3s ease-in-out;
        }
      
        &:after {
          background: transparent;
          z-index: -1;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }

        svg {
          display: flex;
          margin: 4px 8px;
        }
      }
      .watch, .watch-trailer, .add-watchlist {
        background: #00000099;
        border: 1px solid #f9f9f9;
        color: #f9f9f9;
        align-items: center;
        display: flex;
        height: 56px;
        justify-content: center;
        overflow: hidden;
        padding: 0 1rem;
        position: relative;
        transition: all .2s ease-in-out;
        width: auto;
        margin-right: 0.75rem;
        font-size: 1.3rem;
        padding-right: 1.75rem;

        svg {
          display: flex;
          margin: 4px 8px;
        }
      }

      .add-watchlist {
        padding: 0;
      }
    }
  }
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}