.App .admin {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;

  > section {
    height: 100%;
    overflow: hidden;
  }

  section.main {
    //width: calc(70% - 40px);
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    //height: calc(100vh - 100px);
    // padding: 20px;

    .admin-menu-button {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;

    Button {
      background: #000000;
      border: 1px solid #f9f9f9;
      color: #f9f9f9;
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      padding: 0 1rem;
      position: relative;
      transition: all .2s ease-in-out;
      width: auto;
      margin-right: 0.75rem;
      padding-right: 1.75rem;
      font-size: 1.0rem;
      padding-right: 1.75rem;
      border-radius: 2rem;

      svg {
        display: flex;
        margin: 4px 8px;
      }
    }
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    position: relative;
    height: 100px;
    align-items: center;

    .ad-pod {
      position: absolute;
      left: 0;
      z-index: 1;
      height: 25px;
      width: 25px;
      border-radius: 30px;
      border: 5px solid #8d46f7;
      background: white;
    }

    .timeline-bar {
      background-color: #000;
      position: absolute;
      width: 100%;
      height: 10px;
      z-index: 0;
      border-radius: 25px;
    }
  }
}

@media screen and (max-width: 991px) {
  .admin {
  }
}
