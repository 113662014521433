.experiences {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  background-image: linear-gradient(
    -90deg,
    rgba(241, 224, 229, 1) 60%,
    rgba(157, 193, 213, 0.5) 90%
  );

  a {
    text-decoration: none;
  }

  .MuiGrid-root {
    align-content: center;
    justify-content: center;
  }

  &.ad-formats {
    .MuiButtonBase-root {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #e5f0fe;

      img {
        height: auto;
        padding: 10%;
        max-width: 65%;
      }

      > div {
        margin-right: auto;
        background-color: #fff;
        width: 100%;
      }

    }
  }
}

@media screen and (max-width: 991px) {
  .experiences {
    flex-direction: column;
    padding: 25px 0;
    overflow: auto;
    .MuiGrid-root.MuiGrid-container {
      flex-direction: column;
      align-items: center;
    }
  }
}
