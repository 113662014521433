.ad-builder {
  max-width: 1000px;
  width: 100%;
  position: relative;
  h1 {
    margin-block-end: 0em;
  }
  h2 {
    margin-block-start: 0em;
    font-size: 1.2em;
    font-weight: 300;
  }
  .drag-n-drop {
    height: 0;
    overflow: hidden;
    padding-top: 591.44px / 1060px * 100%;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    // background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    p {
      color: #000;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      border-color: #2196f3;
      cursor: pointer;
    }
  }

  .image-cropper {
    .button-container {
      padding: 0;
      justify-content: space-between;
      .left {
        display: flex;
        justify-content: flex-start;
      }
      .right {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .bg-image {
    position: absolute;
    top: 100px;
    z-index: -1;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
}
