.experience {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  position: relative;

  .video-player {
    .vjs-control-bar {
      height: 12em;
      .vjs-button.vjs-control {
        span {
          &:before {
            font-size: 5em;
            display: none;
          }
        }
      }
      .vjs-progress-holder {
        height: 1.0em;
        background-color: rgb(255 255 255 / 30%);
        border-radius: 25px;
  
        > div {
          border-radius: 25px;
        }
  
        .vjs-play-progress {
          background-color: #3EABFF;
          &:before {
            font-size: 2.5em;
          }
        }
  
        .vjs-time-tooltip, .vjs-remaining-time-display {
          font-size: 1.5em;
          border-radius: 25px;
        }
      }
    }

    .vjs-remaining-time {
      display: flex;
      align-items: center;
      justify-content: center;
      .vjs-remaining-time-display {
        font-size: 2.5em;
        border-radius: 25px;
      }
    }
  }

  &.ad-format {
    section.main {
      .video-player-container {
        position: relative;
        padding-top: 58.35%;
        background: #000;
        overflow: hidden;
        width: 100%;

        &.TRIVIA, &.TRIVIAADPOD, &.ENDCARD-TRIVIA {
          .video-player {
            width: 48% !important;
            padding-top: 27.0% !important;
            //margin-left: 5%;
            //top: 25%;
            height: auto;
            margin-left: 15%;
            top: 15%;
            border: 0 none;
  
            .vjs-control-bar {
              display: none;
            }
          }
        }

        .video-player {
          position: absolute;
        }

        .interactive-content {
          position: absolute;
          width: 98%;
          z-index: 9;
          height: 100%;
          top: 0;
          //background: #000;
          overflow: hidden;
          left: 0%;
          top: -10%;
          border: 10px solid #000;
          transform: scale(0.5);
        }
      }
    }
  }

  section {
    &.main {
      position: relative;
      z-index: 1;

      .text-block {
        z-index: 1;
      }
    }
    &.rail {
      position: relative;
      z-index: 1;
    }
  }

  .video-player {
    position: relative;
    z-index: 1;
    width: 98%;

    .video-js {
      padding-top: 54% !important;
    }
  }

  .bg {
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  &.live {
    section {
      &.main {
        .text-block {
          display: none;
        }
      }
    }
    .bg {
      filter: blur(50px);
      -webkit-filter: blur(50px);
      background-size: 200%;
    }
  }

  &.chatDisabled {
    section {
      &.main {
        width: 100%;
      }
    }
  }

  &.ctv {
    &.experience.live {
      .bg {
        filter: blur(0px);
        -webkit-filter: blur(0px);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    .simulated-remote {
      position: absolute;
      max-height: 450px;
      z-index: 10;
      right: 5%;
      bottom: 0;

      img {
        max-height: 700px;
      }
    }
    
    .video-player {
      transform: scale(0.50);
      top: -10%;
      left: 0%;
      position: relative;
      padding-top: 58.35%;
      background: #000;
      overflow: hidden;
      
      -webkit-transition: all .2s;
      -webkit-transition-timing-function: ease-in-out;
      transition: all .2s;
      transition-timing-function: ease-in-out;

      //box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      //box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
      border: 10px solid #000;

      .video-js {
        top: 0;
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding-top: inherit;
        -webkit-transition: all .4s;
        -webkit-transition-timing-function: ease-in-out;
        transition: all .4s;
        transition-timing-function: ease-in-out;

        .vjs-big-play-button, .vjs-control-bar {
          position: absolute;
          z-index: 1000;
        }
      }

      &.TRIVIA, &.TRIVIAADPOD, &.ENDCARD-TRIVIA {
        .video-js {
          width: 48% !important;
          padding-top: 27.0% !important;
          margin-left: 5%;
          top: 25%;
          height: auto;

          .vjs-control-bar {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .App .experience {
    flex-direction: column;

    section {
      &.main {
        width: 100%;
        min-height: 35vh;
      }
      &.rail {
        min-height: calc(65vh - 70px);
      }
    }
  }
}
