.simulated-remote {
  .button-map {
    .button {
      -webkit-transition: all .4s;
      -webkit-transition-timing-function: ease-in-out;
      transition: all .4s;
      transition-timing-function: ease-in-out;

      color: transparent;
      border: transparent;
      position: absolute;
      border-radius: 50px;
      background-color: transparent;

      &:hover {
        cursor: pointer;
        background-color: rgb(242 222 60 / 63%);
      }

      &.up {
        height: 65px;
        width: 65px;
        top: 35px;
        left: 66px;
      }
      &.down {
        height: 65px;
        width: 65px;
        top: 197px;
        left: 66px;
      }
      &.left {
        height: 65px;
        width: 65px;
        top: 118px;
        left: -17px;
      }
      &.right {
        height: 65px;
        width: 65px;
        top: 118px;
        left: 146px;
      }
      &.select {
        height: 95px;
        width: 95px;
        border-radius: 50px;
        top: 99px;
        left: 51px;
      }
      &.back {
        height: 65px;
        width: 65px;
        top: 233px;
        left: 27px;
      }
      &.playPause {
        height: 65px;
        width: 65px;
        top: 308px;
        left: 27px;
      }
      &.active {
        background-color: rgb(242 222 60 / 63%);
      }
    }
  }

}

@media screen and (max-width: 991px) {

}
