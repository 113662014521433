.pause-ad {
  height: 100%;
  width: 35%;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;

  .scene-overlay {
    position: relative;
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 0 5%;

    .feature-brand-logo-lockup {
      position: relative;
      margin-top: 5%;
    }

    .feature-gateway-messaging {
      // margin-top: 10%;
    }
  }

  .featured-background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    height: 100%;
    width: 235%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.5) 35.42%, rgba(0, 0, 0, 0.25) 100%);
    background: radial-gradient(58.86% 58.86% at 50% 50%, rgba(0, 0, 0, 0.31) 81.87%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 4.23%, rgba(0, 0, 0, 0.00812836) 10.51%, rgba(0, 0, 0, 0.0333794) 16.79%, rgba(0, 0, 0, 0.0767603) 23.08%, rgba(0, 0, 0, 0.138567) 29.36%, rgba(0, 0, 0, 0.217868) 35.64%, rgba(0, 0, 0, 0.311971) 41.93%, rgba(0, 0, 0, 0.4261) 48.21%, rgba(0, 0, 0, 0.5539) 54.49%, rgba(0, 0, 0, 0.678) 60.78%, rgba(0, 0, 0, 0.7921) 67.06%, rgba(0, 0, 0, 0.8714) 73.34%, rgba(0, 0, 0, 0.9032) 79.63%, rgba(0, 0, 0, 0.9166) 85.91%, rgba(0, 0, 0, 0.93) 92.2%, rgba(0, 0, 0, 0.94) 98.48%);
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

@media screen and (max-width: 991px) {
  .pause-ad {
  }
}
